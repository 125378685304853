<template>
  <div class="content">
    <div class="contest-submit">
      <div class="wrap">
        <div class="headline clearfix">
          <h1 class="left">{{ title }}</h1>
          <a
            class="btn-back right"
            href="javascript:;"
            @click="$router.history.go(-1)"
            >返回</a
          >
        </div>
        <div class="contest-con">
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-width="120px"
            class="demo-ruleForm info-box clearfix"
          >
            <div class="info-box clearfix">
              <div class="left">
                <el-form-item label="作品标题：" prop="name">
                  <el-input
                    v-model="form.mname"
                    placeholder="请输入15字以内作品标题"
                  ></el-input>
                </el-form-item>
                <el-form-item label="拍摄日期：" prop="shoot_date">
                  <el-input
                    v-model="form.custom_fields.shoot_date"
                    placeholder="请输入拍摄日期 例：2000-01-01"
                  ></el-input>
                </el-form-item>
                <el-form-item label="拍摄地址：" prop="shoot_address">
                  <el-input
                    v-model="form.shoot_address"
                    placeholder="请输入拍摄地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作品说明：" prop="desc">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="form.remark"
                    maxlength="1000"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="投稿类型">
                  <el-radio-group v-model="form.custom_fields.post_img_type">
                    <el-radio label="单幅">单幅</el-radio>
                    <el-radio label="组照">组照（4-8幅拼成一幅上传）</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div class="info-item" style="display: block;">
                  <a
                    class="btn btn-bj"
                    href="javascript:void(0)"
                    @click="triggerUpload()"
                  >从本机选择图片</a>
                  <!-- <a class="btn btn-tk" href="javascript:;" @click="showGallery">从我的图库中选择图片</a> -->
                  <p class="tips-txt" v-show="msg">
                    {{ msg }}
                  </p>
                  <p class="tips-txt">
                    （本赛事仅支持{{ gourp_limit.typestr }}格式且不大于{{
                      gourp_limit.size
                    }}M的图片）
                  </p>
                </div>
              </div>
              <div class="right">
                <el-upload
                  class="upload-singlepic-wrap"
                  id="single"
                  action=""
                  :show-file-list="false"
                  :accept="upAccept"
                  :on-change="handleChange"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :http-request="handleUpload"
                >
                  <img
                    v-if="imageUrl"
                    :src="imageUrl"
                    class="upload-singlepic"
                    @click="clearTips()"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div class="imgLoading-bg" v-show="loading">
                    <div class="imgLoading">
                      <span></span><span></span><span></span><span></span
                      ><span></span><span></span><span></span><span></span>
                    </div>
                  </div>
                </el-upload>
                <p class="error"><span v-show="isShowTips">请上传图片</span></p>
              </div>
            </div>
            <div class="submit-box">
              <div class="submit-item">
                <label>
                  <input
                    class="check"
                    type="checkbox"
                    :checked="isAgreement"
                    @click="isAgreement = !isAgreement"
                  />
                  <span class="text">我同意将该图片同步到app发现中</span>
                </label>
              </div>
              <div class="agreement-tips">
                发布即表示您已同意我们的<a
                  href="http://www.pai2345.com/web/Findworks/agreement"
                  target="_blank"
                  >原创条款</a
                >
              </div>
              <div class="submit-item">
                <a class="btn" href="javascript:;" @click="submitForm()"
                  >提交</a
                >
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <Gallery :transShowGallery="isShowGallery" @changeGallery="hideGallery" :end="1"/>
  </div>
</template>

<script>
import Gallery from "@/components/Gallery.vue";
import EXIF from "exif-js";
export default {
  components: {
    Gallery,
  },
  data() {
    return {
      id: this.$route.params.id,
      title: '单图投稿',
      group: this.$route.query.group,
      // 是否同意条款
      isAgreement: false,
      checkExif: false,
      upAccept: "image/png,image/jpeg",
      define: [],
      custom_fields: [],
      form: {
        mname: "",
        remark: "",
        shoot_address: "",
        custom_fields: {
          shoot_date: "",
          post_img_type: "单幅",
        },
      },
      rules: {},
      loading: false,
      formData: {},
      imageUrl: require("@/static/images/web_single-bg.png"),
      imageBg: require("@/static/images/web_single-bg.png"),
      isShowTips: false,
      isSubmit: false,
      msg: "",
      gourp_limit: {
        size: 0,
        imgtype: [],
        typestr: "",
        is_open_psd: 0,
      },
      isShowGallery: false,
      // singleUploadBg: require("@/static/images/web_single-bg.png")
    };
  },
  methods: {
    triggerUpload() {
      document
        .getElementById("single")
        .querySelector(".el-upload__input")
        .click();
    },
    clearTips() {
      this.isShowTips = false;
    },
    showGallery() {
      this.isShowGallery = true;
    },
    /**
     * 选择文件后获取exif
     * @param file
     * @param filelist
     */
     handleChange(file, filelist) {
      if (file.status == 'ready') {
        let that = this;
        this.form.custom_fields.shoot_date = "";
        this.form.shoot_address = "";
        this.checkExif = false;
        EXIF.getData(file.raw, function(){
          var exifData = EXIF.getAllTags(this);
          // console.log('exif===========', exifData);
          // 判断exif信息
          that.checkExif = false;
          if(typeof exifData.DateTimeOriginal !== 'undefined'){
            let dateTime = exifData.DateTimeOriginal.replace(/:/g, "-");
            dateTime = dateTime.split(" ");
            that.form.custom_fields.shoot_date = dateTime[0];
            that.checkExif = true;
          }
          // 判断是否有坐标信息
          // if(typeof exifData.GPSLatitude !== 'undefined'){
          //   let latitude = exifData.GPSLatitude[0]+exifData.GPSLatitude[1]/60+exifData.GPSLatitude[2]/60/60;
          //   let longitude = exifData.GPSLongitude[0]+exifData.GPSLongitude[1]/60+exifData.GPSLongitude[2]/60/60;
          //   console.log(latitude, longitude);
          // }
        });
      }
    },
    handleAvatarSuccess(res, file) {
      // 上传成功改变图片值
      this.imageUrl = URL.createObjectURL(file.raw);
      // 图片上传成功就隐藏提示框
      this.isShowTips = false;
    },
    beforeAvatarUpload(file) {
      //类型判断need imgtype: [],
      let isJPG = this.tools.hasImgType(this.gourp_limit.imgtype, file.type);
      let isLt2M = file.size / 1024 / 1024 < this.gourp_limit.size;
      if (!isJPG) {
        this.$message.error(
          "上传图片只能是 " + this.gourp_limit.typestr + " 格式!"
        );
        return false;
      }
      if (file.size / 1024 / 1024 < 3) {
        this.$message.error(
          "上传文件大小不能小于3MB!"
        );
        return false;
      }
      if (!isLt2M) {
        this.$message.error(
          "上传图片大小不能超过 " + this.gourp_limit.size + "MB!"
        );
        return false;
      }
      return isJPG && isLt2M;
    },
    handleUpload(option) {
      var file = option.file;
      var that = this;
      that.loading = true;
      this.tools.uploadaly(file).then((res) => {
        if (res) {
          that.imageUrl = res;
          that.loading = false;
        } else {
          console.log(res);
        }
      });
    },
    hideGallery(arr) {
      this.isShowGallery = false;
      if(arr.length>0){
          this.imageUrl = arr[0];
      }
    },
    submitForm() {
      let that = this;
      if (this.isSubmit == false) {
        if (this.form.mname == "") {
          this.$message.error("作品标题必须填写");
          return false;
        }
        if (this.imageUrl == this.imageBg) {
          this.$message.error("请上传图片");
          this.isShowTips = true;
          return false;
        }
        if (this.loading) {
          this.$message.error("图片上传中，请稍候...");
          return false;
        }
        if (this.form.custom_fields.shoot_date == "") {
          this.$message.error("拍摄日期必须填写");
          return false;
        }
        if (this.form.shoot_address == "") {
          this.$message.error("拍摄地址必须填写");
          return false;
        }
        if (this.form.remark == "") {
          this.$message.error("作品说明必须填写");
          return false;
        }
        let custom_fields = {
          shoot_date: this.form.custom_fields.shoot_date,
          post_img_type: this.form.custom_fields.post_img_type,
        };
        this.isSubmit = true;
        this.axios
          .post("/match/join_single", {
            work_id: this.$route.query.work_id,
            match_id: that.id,
            mname: this.form.mname,
            remark: this.form.remark,
            image: this.imageUrl,
            group: this.group,
            shoot_address: this.form.shoot_address,
            custom_fields: custom_fields,
            is_finding: this.isAgreement,
            works_type: 1,
            source: 2,
          })
          .then(
            (res) => {
              if (res.data.code == 200) {
                this.$confirm("投稿成功!", "确认信息", {
                  distinguishCancelAndClose: true,
                  confirmButtonText: "再投一稿",
                  cancelButtonText: "返回我的作品",
                })
                  .then(() => {
                    var initUrl = window.location.href;
                    initUrl = initUrl.replace("work_id", "init");
                    window.location.href = initUrl;
                    window.location.reload();
                  })
                  .catch(() => {
                    this.$router.push({
                      path: "/detail/" + that.id + "/myworks",
                    });
                  });
              } else {
                this.$message(res.data.msg);
                this.isSubmit = false;
              }
            },
            (err) => {
              this.isSubmit = false;
              console.log(err);
            }
          );
      }
    },
    init(){
      this.tools
          .getMatchDetail({ id: this.id, group: this.group })
          .then((res) => {
            this.title = res.gourp_limit['groupname']+'投稿';
            this.msg = res.match_limit.match_introduce;
            this.define = res.custom_fields;
            if (res.match_limit.is_app_show == 1) {
                this.isAgreement = true;
            }
            if (res.gourp_limit) {
                this.gourp_limit = Object.assign(this.gourp_limit, res.gourp_limit);
                this.gourp_limit.size = res.gourp_limit.file_limit_single;
                this.gourp_limit.imgtype = res.gourp_limit.file_limit_type_single;
                this.gourp_limit.typestr = this.gourp_limit.imgtype.toString();
            }
          });
    },
  },
  mounted() {
    if (this.$route.query.work_id) {
      this.tools
        .getWorksDetail({ work_id: this.$route.query.work_id })
        .then((res) => {
          this.form = res.form;
          this.imageUrl = res.image;
          this.id = this.form.m_id;
          this.group = this.form.group;
          this.init();
        });
    }else {
      this.init();
    }
  },
};
</script>

<style>
/* .contest-submit .info-box .left .info-item.textarea-box {
  align-items: top;
}
.contest-submit .info-box .left .textarea-box .label {
  vertical-align: top;
  display: inline-block;
  height: 150px;
} */
/* .contest-submit .info-box .left .info-item .label {
  min-width: 90px;
} */
.contest-submit .info-box .left .el-textarea__inner {
  width: 100%;
}
.el-form-item__label {
  font-size: 16px;
}
.contest-submit .info-box .left .is-error textarea {
  border-color: #f56c6c;
}
.error {
  line-height: 24px;
  height: 24px;
  color: #f56c6c;
}
.el-input__inner:focus,
.el-input__inner:hover {
  border-color: #ddd;
}
.el-textarea .el-input__count {
  line-height: 16px;
  right: 20px;
}
.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 120px;
  height: 120px;
  border: 1px solid #eee;
  border-radius: 0;
}
.contest-submit .info-box .left .info-item{
  text-align: center;
}
.contest-submit .left .el-form-item .el-checkbox__label,
.contest-submit .left .el-form-item .el-radio__label
{
  font-size: 16px!important;
}

.drag-list .drag-item{
  display: flex;
  margin-bottom: 15px;
  cursor: move;
  position: relative;
}
.drag-list .drag-item .img{
  width:120px;
  height:80px;
  cursor: move;
}
.drag-list .drag-item .img img{
  width:100%;
  height:100%;
  border-radius:5px;
  cursor: move;
}
.drag-list .drag-item .desc{
  flex: 1;
  padding: 0 30px 0 0;
  box-sizing: border-box;
  height: 80px;
  overflow: auto;
  text-align: justify;
  margin-left: 10px;
}
.drag-list .drag-item .desc .ipt{
  width:100%;
  height: 100%;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner{
  width:100%;
  height: 100%;
  resize: none;
}
.drag-list .drag-item .desc .ipt .el-textarea__inner:focus{
  border-color: #ef7f48;
}
.drag-list .drag-item .desc::-webkit-scrollbar{
  width: 6px;
}
.drag-list .drag-item .desc::-webkit-scrollbar-thumb{
  background: #ccc;
}
.drag-list .drag-item .desc::-webkit-scrollbar-track-piece {
  background: #f6f6f6;
}
.drag-list .drag-item:hover{
}
.drag-list .chosenDragItem{
  box-sizing: border-box;
  overflow: hidden;
}
.drag-list .drag-item .btn-group{
  height: 80px;
  position: absolute;
  right: 0px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f5f5;
  display: none;
}
.drag-list .drag-item .btn-group i{
  width:24px;
  height:24px;
  display: flex;
  justify-content:center;
  align-items: center;
  cursor: pointer;
  color: #333;
}
.drag-list .drag-item .btn-group i:hover{
  color: #ef7f48;
  background:#d2d2d2;
}
.drag-list .drag-item:hover .btn-group{
  display: flex;
}
.contest-submit .left .agree-l-b{
  display: flex;
  justify-content: center;
  font-size: 16px;
  margin: 15px 0;
}
.contest-submit .left .agree-l-b .check{
  vertical-align: middle;
}
</style>
